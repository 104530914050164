import React from "react";
import * as styles from "./body.module.scss";
import CaseStudyItem from "../../../atoms/caseStudyItem";
import bgimage1 from "../../../../images/46.jpg";
import bgimage2 from "../../../../images/pc2.1.jpg";
import bgimage3 from "../../../../images/47.jpg";
import SecondSubscribe from "../../../molecules/Magazine/SecondSubscribe";
import InstaSlider from "../../../molecules/Magazine/instaSlider";
import WizrWay from "../../../molecules/Magazine/wizrWay";



const BlogBody = ({ content, type, extraFileds}) => {
  return (
    <div className={`${styles.body} ${type === "quote" && styles.bodyQuote}`}>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
        style={type == "quote" ? { paddingTop: "17rem" } : {}}
      ></div>

      {extraFileds.articles && (
        <div className={styles.caseStudies}>
       <CaseStudyItem 
    description="6 Hacks for your presentation improvement"
    image={bgimage1}
    extraClass="largeArticle"
    link="/blog/6-steps-to-improve-your-presentation"
    />
    <CaseStudyItem 
    description="10 Tips to make your presentation awesome"
    image={bgimage2}
    extraClass="largeArticle1"
    link="/blog/10-steps-to-awesome-presentations"

    />
    <CaseStudyItem 
    description="4 New ways to look at your presentations"
    image={bgimage3}
    extraClass="largeArticle"
    link="/blog/4-new-ways-to-look-at-your-presentations"
    />
        </div>
      )}
      <div className={styles.subscribe}>
        {extraFileds.showSubscribe && <SecondSubscribe />}
        {extraFileds.instagramPosts && <InstaSlider />}
        {/* <WizrWay /> */}
      </div>
    </div>
  );
};

export default BlogBody;
