import React from "react";
import * as styles from "./article.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import NewsTitle from "../../../atoms/newsTitle";

const BlogArticle = ({ type }) => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(limit: 4) {
          edges {
            node {
              title
              slug
              content
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const featuredCard = (post) => {
    const img = getImage(
      post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
    );

    return (
      <div className={styles.featuredContainer}>
        <GatsbyImage alt="test" image={img} className={styles.image} />
        <h3>{post.title}</h3>
        {/*<div className={styles.excerpt} dangerouslySetInnerHTML={{__html:excerpt}} />*/}
        <div className={styles.excerpt}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore.
        </div>
        <Link className={styles.link} to="#">
          {" "}
          Read More >{" "}
        </Link>
      </div>
    );
  };
  const defaultCard = (post) => {
    return (
      <div className={styles.defaultContainer}>
        <p>Article</p>
        <h3>{post.title}</h3>
        <Link to="#" className={styles.link}>
          {" "}
          Read More >{" "}
        </Link>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {type !== "background_image" ? <h2>Latest Article</h2> : null}
        <div
          className={`${styles.cardsContainer} ${
            type == "background_image" && styles.fourColumns
          }`}
        >
          {type !== "background_image"
            ? allWpPost.edges.slice(0, 3).map((post) => {
                return featuredCard(post.node);
              })
            : allWpPost.edges.slice(0, 4).map((post) => {
                return defaultCard(post.node);
              })}
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
