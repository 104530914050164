import React from "react";
import { graphql } from "gatsby";
import Footer from "../../atoms/footer";
import Header from "../../atoms/header";
import BlogHeader from "./header";
import FreeTrial from "../../atoms/freeTrial";
import BlogBody from "./body";
import BlogTags from "./tags";
import BlogArticle from "./article";

const BlogPostTemplate = ({ data }) => {
  const type = data.wpPost.articleTemplate.template[0];
  const tags = data.wpPost.tags.nodes;

  const { bannerDesc, bannerTitle, bottomArticles, breadcrumbs} = data?.wpPost?.articlesD;
  
  return (
    <main>
      <Header />
      <BlogHeader
        type={type}
        image={
          data?.wpPost?.articlesD?.bannerImage?.localFile?.childrenImageSharp[0]?.gatsbyImageData
        }
        title={bannerTitle}
        description = {bannerDesc}
        date={data.wpPost.date}
        categories={data.wpPost.categories.nodes}
        showBreadcrumbs = {breadcrumbs}
      />
      <BlogBody
        extraFileds={data.wpPost.articlesD}
        content={data.wpPost.content}
        type={type}
      />
      <BlogTags type={type} tags={tags} />
      {bottomArticles && <BlogArticle type={type} /> }
      <FreeTrial />
      <Footer />
    </main>
  );
};

export default BlogPostTemplate;
export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMM DD, Y")
      articleTemplate {
        template
      }
      articlesD {
        bannerImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
        showSubscribe
        articles
        bannerDesc
        bannerTitle
        bottomArticles
        instagramPosts
        breadcrumbs
      }
    
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
  }
`;
