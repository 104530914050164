import React from 'react';
import * as styles from "./tags.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import StyledButton from "../../../atoms/StyledButton";
import { IoIosArrowForward } from "react-icons/io";

const BlogTags = ({type, tags}) => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                {type !== 'quote' && <h3>TAG</h3>}
                <div className={styles.body}>
                    <div className={styles.tags}>

                        {
                            tags.map((tag) => {
                                return <span>
                                    {type == 'quote'
                                        ? '#' + tag.name.toLowerCase().replace(/ /g, '')
                                        : tag.name
                                    }
                                </span>;
                            })
                        }
                    </div>
                    <div className={styles.socialIcons}>Share :
                        <a href="https://www.facebook.com/masterwizr" target="_blank">  <FontAwesomeIcon icon={faFacebookF} style={{paddingLeft: 5, height:15, color: '#ADADAD'}}/> </a>
                        <a href="https://www.instagram.com/masterwizr/?hl=en" target="_blank"> <FontAwesomeIcon icon={faInstagram} style={{paddingLeft: 5, height:15, color: '#ADADAD'}}/> </a>
                        <a href="https://no.linkedin.com/company/masterwizr" target="_blank"> <FontAwesomeIcon icon={faLinkedinIn} style={{paddingLeft: 5, height:15, color: '#ADADAD'}}/> </a>
                    </div>
                </div>
                <hr/>
                <StyledButton 
          title="Go Back"
          extraClass="graybtn"
          link="../magazine"
          email={true}
          icon={<IoIosArrowForward/>}
          />
            </div>
        </div>
    );
};

export default BlogTags;
