// extracted by mini-css-extract-plugin
export var article = "headerBlog-module--article--JrQts";
export var bgImage = "headerBlog-module--bgImage--A936Q";
export var containerBg = "headerBlog-module--containerBg--N50aa";
export var containerBgInner = "headerBlog-module--containerBgInner--hgDHL";
export var containerDefault = "headerBlog-module--containerDefault--YvpQT";
export var containerDefaultInner = "headerBlog-module--containerDefaultInner--Y8JYh";
export var containerQuote = "headerBlog-module--containerQuote--6rQsO";
export var image = "headerBlog-module--image--64dED";
export var logo = "headerBlog-module--logo--+K08e";
export var magazine = "headerBlog-module--magazine--pjMZY";
export var tag = "headerBlog-module--tag--A0rJg";