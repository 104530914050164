import React from 'react';
import * as styles from './newsTitle.module.scss';
import {Link} from 'gatsby';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewsTitle = ({title, link}) => {
    return (
        <div className={styles.title}>
            <Link to={link}>
                <p>{title}</p>
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className={styles.chevronRight}
                />
            </Link>
            
        </div>
    );
};

export default NewsTitle;
