import React from "react";
import * as styles from "./headerBlog.module.scss";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import logo from "../../../../images/logoHolder.png"

const BlogHeader = ({
  type,
  title,
  image,
  date,
  categories,
  showBreadcrumbs,
  description,
}) => {
  
  const img = getImage(image);
  const defaultView = () => {
    return (
      <div className={styles.containerDefault}>
        <div className={styles.containerDefaultInner}>
          {showBreadcrumbs && (
            <span className={styles.magazine}>
              Magazine | <span className={styles.article}>Article</span>
            </span>
          )}
          <h1>{title}</h1>
          <GatsbyImage alt="test" image={img} className={styles.image} />
        </div>
      </div>
    );
  };

  const bgView = () => {
    return (
      <div
        className={styles.containerBg}
        style={{ backgroundImage: `url(${img?.images?.fallback.src})` }}
      >
        <div className={styles.containerBgInner}>
          {showBreadcrumbs && (
            <span className={styles.magazine}>
              Magazine | <span className={styles.article}>Article</span>
            </span>
          )}
          <img className={styles.logo} src={logo} alt='MasterWizr'/>
          <h1>{title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.description}
          ></div>
        </div>
      </div>
    );
  };
  const quoteView = () => {
    return (
      <div className={`${styles.containerDefault} ${styles.containerQuote} `}>
        <div className={styles.containerDefaultInner}>
          {/* {showBreadcrumbs && (
            <span className={styles.magazine}>
              Magazine | <span className={styles.article}>Article</span>
            </span>
          )} */}
          <h1>{title}</h1>
          <span className={styles.tag}>
            {date} <span> {categories[0].name}</span>
          </span>
          <GatsbyImage alt="test" image={img} className={styles.bgImage} />
        </div>
      </div>
    );
  };

  const renderView = (view) => {
    switch (view) {
      case "quote":
        return quoteView();
      case "background_image":
        return bgView();
      default:
        return defaultView();
    }
  };

  return <div>{renderView(type)}</div>;
};

export default BlogHeader;
